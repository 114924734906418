<template>
	<div>
		<!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->
		<svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
			<g clip-path="url(#clip0_1808_2657)">
				<path d="M24.1855 23.1465L17.7395 16.7005C19.3455 14.9265 20.332 12.5815 20.332 10C20.332 4.477 15.855 0 10.332 0C4.80903 0 0.332031 4.477 0.332031 10C0.332031 15.523 4.80903 20 10.332 20C12.9135 20 15.2585 19.0135 17.0325 17.4075L23.4785 23.8535L24.1855 23.1465ZM10.332 19C5.36953 19 1.33203 14.9625 1.33203 10C1.33203 5.0375 5.36953 1 10.332 1C15.2945 1 19.332 5.0375 19.332 10C19.332 14.9625 15.2945 19 10.332 19Z" fill="#222222"/>
			</g>
			<defs>
				<clipPath id="clip0_1808_2657">
					<rect width="24" height="24" fill="white" transform="translate(0.332031)"/>
				</clipPath>
			</defs>
		</svg>
	</div>
</template>

<script>
export default {
	name: "search-icon",
};
</script>
