import { suggestionForSize } from "@/utils";

export default {
	name: "loadingSuggestion",
	components: {},
	data() {
		return {
			numberElement: {
				type: Number,
				required: false,
			},
		};
	},
	created() {
		this.numberElement = suggestionForSize();
	},
};
