<template>
	<div class="root-result-container">
		<div v-if="this.$store.state.suggestions && this.$store.state.suggestions.suggestionView && this.$store.state.searchResponse.serviceExecuted == true" class="header-container">
			<SearchSuggestions :onKeyUpProp="this.onKeyUpProp"></SearchSuggestions>
			<a class="show-all" :href="composeShowAllUrl()" @click="trackViewAllAnalytics()">
				<span>Show all {{ this.$store.state.searchResponse.recordSetTotal }} results</span>
				<svg xmlns="http://www.w3.org/2000/svg" width="9" height="16" viewBox="0 0 9 16" fill="none">
					<path d="M1.13798 15.8046L0.195312 14.862L7.05698 7.99998L0.195312 1.13798L1.13798 0.195312L8.94298 7.99998L1.13798 15.8046Z" fill="#575D9B" />
				</svg>
			</a>
		</div>
		<LoadingSuggestion v-else></LoadingSuggestion>
		<TilesGrid :items="this.$store.state.searchResponse.response" v-if="this.$store.state.searchResponse.response.length > 0" section="result"></TilesGrid>
		<LoadingTilesGrid v-else></LoadingTilesGrid>
	</div>
</template>

<script>
import resultComponent from "./resultComponent.js";
export default {
	...resultComponent,
};
</script>
<style lang="scss">
@import "./resultComponent.scss";
</style>
