import ResultComponent from "./resultComponent/ResultComponent.vue";
import NoResultComponent from "./noResultComponent/NoResultComponent.vue";

export default {
	name: "searchResult",
	components: {
		ResultComponent,
		NoResultComponent
	},
	props: {
		onKeyUpProp: {
			type: Function,
		},
	},
	data() {
	},
	methods: {
	},
	data() {},
	methods: {},
};
