import TilesGrid from "../tilesGrids/tilesGrid/TilesGrid.vue";
import LoadingTilesGrid from "../tilesGrids/loadingTilesGrid/LoadingTilesGrid.vue";
import * as utils from "@/utils";

export default {
	name: "noResult",
	components: {
		TilesGrid,
		LoadingTilesGrid,
	},
	data() {
		return {
			items: [],
			numberElements: 0,
		};
	},
	created() {
		if (this.$store.state.noResultsProductsResponse.response.length == 0 || !this.$store.state.noResultsProductsResponse.serviceExecuted) {
			this.retiveEspotByName("X_NoResults_Products");
			this.getItemForSize();
		}
	},
	methods: {
		async retiveEspotByName(name) {
			let mocos = await utils
				.getEspotByName(this.$store.state.data, name)
				.then(async (response) => {
					let partNumbersArray = utils.splitPartNumbers(response.data.MarketingSpotData[0].baseMarketingSpotActivityData[0].marketingContentDescription[0].marketingText);
					await utils.doSearchByPartNumber(this.$store.state.data, partNumbersArray).then(async (response) => {
						const requestForSearchByTerm = utils.createSearchByPartNumberResponse(utils.sortByPartNumberResponse(response, partNumbersArray));
						await this.handleFetching(requestForSearchByTerm);
					});
				})
				.catch((error) => {
					console.error("Errore nel recupero dei dati di marketing:", error);
				});
		},
		async handleFetching(request) {
			for (let i = 0; i < utils.itemsForSize(5, 2, 2); i++) {
				this.items.push(request[i]);
			}
			var currentNoResultsProductsResponse = {
				serviceExecuted: true,
				response: this.items,
			};
			this.$store.commit("setNoResultsProductsResponse", currentNoResultsProductsResponse);
		},
		getItemForSize() {
			this.numberElements = utils.itemsForSize(5, 2, 2);
		},
	},
};
