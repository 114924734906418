<template>
	<div>
		<TilesGrid :items="this.$store.state.noResultsProductsResponse.response" v-if="this.$store.state.noResultsProductsResponse.response.length > 0 && this.$store.state.noResultsProductsResponse.serviceExecuted" section="noresult"></TilesGrid>
		<LoadingTilesGrid :numberElements="this.numberElements" v-else></LoadingTilesGrid>
	</div>
</template>
<script>
import noResult from "./noResult.js";
export default {
	...noResult,
};
</script>
<style lang="scss">
@import "./noResult.scss";
</style>
