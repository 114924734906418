import { trackAnalytics } from "@/utils";

export default {
	name: "searchHistory",
	components: {},
	data() {},
	mounted() {},
	props: {
		onKeyUpProp: {
			type: Function,
		},
	},
	methods: {
		updateInputText(elem) {
			this.$store.commit("setInputText", elem);
			if (!!this.onKeyUpProp) this.onKeyUpProp(elem);
			this.trackHistory(elem);
		},
		trackHistory(historyName) {
			trackAnalytics("Click", "link", "SearchPanel_Results_query", historyName);
		},
	},
};
