<template>
	<div v-if="suggestionEntries.length > 0 && suggestionEntries.some((entry) => entry.term !== this.$store.state.inputText)" class="root-suggestions-container">
		<span class="title">Suggestions</span>
		<div class="suggestion-container">
			<template v-for="(item, index) in suggestionEntries">
				<div v-if="item.term != this.$store.state.inputText" :key="index" class="suggestion-item" v-html="highlightNonMatchingText(item.term)" @click="updateInputText(item.term)"></div>
			</template>
		</div>
	</div>
</template>

<script>
import searchSuggestions from "./searchSuggestions.js";
export default {
	...searchSuggestions,
};
</script>
<style lang="scss">
@import "./searchSuggestions.scss";
</style>
