<template>
	<div class="root-no-result-container">
		<span
			>Sorry, we couldn’t find any results for: <br><strong>{{ this.$store.state.inputText }}</strong></span
		>
		<br />
		<p class="inspiration-label">Looking for inspiration?</p>
		<NoResult></NoResult>
		<EspotContainer espotName="X_Search_NoResults" />
	</div>
</template>

<script>
import noResultComponent from "./noResultComponent.js";
export default {
	...noResultComponent,
};
</script>
<style lang="scss">
@import "./noResultComponent.scss";
</style>
