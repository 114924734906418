import EspotContainer from "@/components/basicComponents/espotContainer/EspotContainer.vue";
import SearchHistory from "@/components/basicComponents/searchHistory/SearchHistory.vue";
import BestSeller from "@/components/basicComponents/bestSeller/BestSeller.vue";
import { trackAnalytics } from "@/utils";

export default {
	name: "searchHome",
	components: {
		EspotContainer,
		SearchHistory,
		BestSeller,
	},
	props: {
		onKeyUpProp: {
			type: Function,
		},
	},
	methods: {
		getItemsInRow(rowIndex) {
			const screenWidth = window.innerWidth;
			let itemsPerRow;

			if (screenWidth < 600) {
				itemsPerRow = 2;
			} else if (screenWidth < 900) {
				itemsPerRow = 3;
			} else {
				itemsPerRow = 3;
			}
			const startIndex = rowIndex * itemsPerRow;
			const endIndex = startIndex + itemsPerRow;
			return this.items.slice(startIndex, endIndex).map((item, index) => ({ item, index }));
		},
		trackClick(productName) {
			trackAnalytics("Click", "hiddenaction", "SearchPanel_Content_link", productName);
		},
	},
	mounted() {
		// Aggiungi la funzione al contesto globale
		window.trackClick = this.trackClick;
	},
};
