<template>
	<div id="skeleton-suggestion">
		<div class="loading suggestion" :class="{ 'small': element % 4 === 1, 'medium': element % 4 === 0, 'big': element % 4 === 3 }" v-for="element in this.numberElement" v-bind:key="element" />
	</div>
</template>

<script>
import loadingSuggestion from "./loadingSuggestion.js";
export default {
	...loadingSuggestion,
};
</script>
<style lang="scss">
@import "./loadingSuggestion.scss";
</style>
