import { trackAnalytics } from "@/utils";

export default {
	name: "searchSuggestions",
	components: {},
	props: {
		onKeyUpProp: {
			type: Function,
		},
	},
	data() {},
	mounted() {},
	computed: {
		suggestionEntries() {
			return this.$store.state.suggestions?.suggestionView?.[0]?.entry || [];
		},
		inputText() {
			return this.$store.state.inputText || "";
		},
	},
	methods: {
		highlightNonMatchingText(item) {
			if (!this.inputText.trim()) return `<strong>${item}</strong>`;

			let parts = item.split(new RegExp(`(${this.escapeRegExp(this.inputText)})`, "gi"));
			return parts.map((part) => (part.toLowerCase() === this.inputText.toLowerCase() ? part : `<strong>${part}</strong>`)).join("");
		},
		escapeRegExp(text) {
			return text.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
		},
		updateInputText(elem) {
			this.$store.commit("setInputText", elem.replace(/&amp;/g, "&"));
			if (!!this.onKeyUpProp) this.onKeyUpProp(elem.replace(/&amp;/g, "&"));
			this.trackSuggestion(elem.replace(" ", "-").toLowerCase());
		},
		trackSuggestion(suggestionName) {
			trackAnalytics("Click", "hiddenaction", "SearchPanel_Results_cta", suggestionName);
		},
	},
};
