import Tile from "../../tiles/tile/Tile.vue";

export default {
	name: "tilesGrid",
	components: {
		Tile,
	},
	props: {
		items: {
			type: Array,
			required: true,
		},
		moduleDesktop: {
			type: Number,
			default: 5,
			required: false,
		},
		moduleTablet: {
			type: Number,
			default: 3,
			required: false,
		},
		moduleMobile: {
			type: Number,
			default: 2,
			required: false,
		},
		section: {
			type: String,
			required: true,
		},
	},
};
