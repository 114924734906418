import LoadingTilesGrid from "@/components/basicComponents/tilesGrids/loadingTilesGrid/LoadingTilesGrid.vue";
import TilesGrid from "@/components/basicComponents/tilesGrids/tilesGrid/TilesGrid.vue";
import SearchSuggestions from "@/components/basicComponents/searchSuggestions/SearchSuggestions.vue";
import LoadingSuggestion from "@/components/basicComponents/searchSuggestions/loadingSuggestion/LoadingSuggestion.vue";
import { trackAnalytics } from "@/utils";

export default {
	name: "resultComponent",
	props: {
		onKeyUpProp: {
			type: Function,
		},
	},
	components: {
		SearchSuggestions,
		LoadingTilesGrid,
		TilesGrid,
		LoadingSuggestion,
	},
	data() {},
	methods: {
		composeShowAllUrl() {
			let url = "";
			if (this.$store.state.data && this.$store.state.searchResponse.searchKeyword) {
				url = "/SearchDisplay?searchTerm=" + this.$store.state.searchResponse.searchKeyword + "&beginIndex=0&pageSize=&catalogId=" + this.$store.state.data.catalogId + "&langId=-1&storeId=" + this.$store.state.data.storeId + "&viewAllClicked=true";
			}
			return url;
		},
		trackViewAllAnalytics() {
			trackAnalytics("Click", "hiddenaction", "SearchPanel_Results_ViewAll", "show-all-results");
		},
	},
};
