<template>
	<div>
		<span class="title">Best Sellers</span>
		<TilesGrid :items="this.$store.state.bestSellerResponse.response" v-if="this.$store.state.bestSellerResponse.response.length > 0 && this.$store.state.bestSellerResponse.serviceExecuted" section="bestseller"></TilesGrid>
		<LoadingTilesGrid :numberElements="this.numberElements" v-else></LoadingTilesGrid>
	</div>
</template>
<script>
import bestSeller from "./bestSeller.js";
export default {
	...bestSeller,
};
</script>
<style lang="scss">
@import "./bestSeller.scss";
</style>
