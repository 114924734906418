<template>
	<div class="wrapper">
		<div class="element box header discount" data-id="0"></div>
		<div class="element box header colors" data-id="1"></div>
		<div class="element box header badge first" data-id="2"></div>
		<div class="element box header badge second" data-id="3"></div>
		<div class="element box body img" data-id="4"></div>
		<div class="element box footer style" data-id="5"></div>
		<div class="element box footer brand" data-id="6"></div>
		<div class="element box footer price" data-id="7"></div>
	</div>
</template>
<script>
import loadingTile from "./loadingTile.js";
export default {
	...loadingTile,
};
/*document.addEventListener("DOMContentLoaded", () => {
	// Remove the setTimeout in production
	setTimeout(() => (document.getElementById("skeleton").style.display = "none"), 3000);
}); */
</script>
<style lang="scss">
@import "./loadingTile.scss";
</style>
