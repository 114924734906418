import LoadingTile from "../../tiles/loadingTile/LoadingTile.vue";
import { itemsForSize } from "@/utils";

export default {
	name: "loadingTilesGrid",
	components: {
		LoadingTile,
	},
	props: {
		numberElements: {
			type: Number,
			required: false,
		},
	},
	data() {
		return {
			elements: {
				type: Number,
				default: 0,
			},
		};
	},
	created() {
		if (this.numberElements == undefined) {
			this.elements = itemsForSize(5, 6, 6);
		} else {
			this.elements = this.numberElements;
		}
	},
};
