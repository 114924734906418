<template>
    <div class="custom-input">
        <input ref="inputTextRef" type="text" v-model="this.$store.state.inputText" @keyup="onKeyUp"
            enterkeyhint="search" placeholder="Search for your favorite styles" id="inputText" />
        <div v-if="this.leftIcon" class="left-icon-container">
            <component @click="this.clickIcon('left')" :is="this.leftIcon" />
        </div>
        <div v-if="this.rightIcon &&  this.$store.state.inputText.length > 0" class="right-icon-container">
            <component @click="this.clickIcon('right')" :is="this.rightIcon" />
        </div>
    </div>
</template>

<script>
import inputText from "./inputText.js";
export default {
    ...inputText,
};
</script>
<style lang="scss">
@import "./inputText.scss";
</style>
