<template>
	<div id="skeleton">
		<LoadingTile v-for="element in this.elements" />
	</div>
</template>
<script>
import loadingTilesGrid from "./loadingTilesGrid.js";
export default {
	...loadingTilesGrid,
};
</script>
<style lang="scss">
@import "./loadingTilesGrid.scss";
</style>
