<template>
	<div class="root-home-container">
		<div class="left-container">
			<SearchHistory :onKeyUpProp="this.onKeyUpProp"></SearchHistory>
			<EspotContainer espotName="X_Search_Suggestion" />
		</div>
		<div class="best-seller-container">
			<BestSeller></BestSeller>
		</div>
	</div>
</template>

<script>
import searchHome from "./searchHome.js";
export default {
	...searchHome,
};
</script>
<style lang="scss">
@import "./searchHome.scss";
</style>
