<template>
    <div v-if="this.$store.state.searchHistoryArray.length > 0" class="root-history-container">
        <ul>
            <li v-for="(elem, index) in this.$store.state.searchHistoryArray" :key="index" @click="updateInputText(elem)">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 16 16" fill="none">
                    <g clip-path="url(#clip0_557_38996)">
                        <path
                            d="M8 0C3.589 0 0 3.589 0 8C0 12.411 3.589 16 8 16C12.411 16 16 12.411 16 8C16 3.589 12.411 0 8 0ZM8 15.3333C3.95633 15.3333 0.666667 12.0437 0.666667 8C0.666667 3.95633 3.95633 0.666667 8 0.666667C12.0437 0.666667 15.3333 3.95633 15.3333 8C15.3333 12.0437 12.0437 15.3333 8 15.3333Z"
                            fill="#575D9B" />
                        <path d="M8.33333 2.33333H7.66667V8.138L11.7643 12.2357L12.2357 11.7643L8.33333 7.862V2.33333Z"
                            fill="#575D9B" />
                    </g>
                    <defs>
                        <clipPath id="clip0_557_38996">
                            <rect width="16" height="16" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
                {{ elem }}
            </li>
        </ul>
    </div>
</template>

<script>
import searchHistory from './searchHistory.js';
export default {
    ...searchHistory,
};
</script>  
<style lang="scss">
@import './searchHistory.scss';
</style>