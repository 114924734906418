<template>
	<div class="root-table-container">
		<div class="row" role="list">
			<Tile v-for="(subItem, index) in this.items" :data="subItem" :index="index" :key="index" class="tile" :section="section" />
		</div>
	</div>
</template>

<script>
import tilesGrid from "./tilesGrid.js";
export default {
	...tilesGrid,
};
</script>
<style lang="scss">
@import "./tilesGrid.scss";
</style>
