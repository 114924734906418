<template>
	<ResultComponent v-if="(this.$store.state.searchResponse.serviceExecuted == false || typeof this.$store.state.suggestions.suggestionView == 'undefined') || this.$store.state.searchResponse.response.length > 0" :onKeyUpProp="this.onKeyUpProp"></ResultComponent>
	<NoResultComponent v-else></NoResultComponent>
</template>

<script>
import searchResult from "./searchResult.js";
export default {
	...searchResult,
};
</script>
<style lang="scss">
@import "./searchResult.scss";
</style>
