<template>
	<SearchComponent :server-date="serverDate"></SearchComponent>
</template>

<script>
import SearchComponent from "./components/searchComponent/SearchComponent.vue";

export default {
	name: "App",
	components: {
		SearchComponent,
	},
	data() {
		return {
			serverDate: {
				catalogId: "",
				storeId: "",
				earlyAccessEnabled: false,
				catGroupCatIdentifier: {},
			},
		};
	},
	created() {
		this.initializeData();
		this.initializeSearchHistoryArray();
	},
	methods: {
		initializeData() {
			// Recupera i dati dall'elemento HTML
			const modalRoot = document.getElementById("search-redesign-modal-root");
			if (modalRoot != undefined) {
				this.serverDate.catalogId = modalRoot.getAttribute("data-catalog-id");
				this.serverDate.storeId = modalRoot.getAttribute("data-store-id");
				this.serverDate.earlyAccessEnabled = modalRoot.getAttribute("data-early-access");
				this.serverDate.catGroupCatIdentifier = this.retriveCatGroupIdsJSON(modalRoot.getAttribute("data-catGroup-catIdentifier"));
				this.serverDate.sequenceCategoryId = modalRoot.getAttribute("data-sequence-category-id");
        this.serverDate.inStockFacet = modalRoot.getAttribute("data-in-stock");
			} else {
				//TO DO: DA RIMUOVER PERCHE' USATI SOLO IN LOCALE
				this.serverDate.catalogId = "22601";
				this.serverDate.storeId = "15951";
				this.serverDate.earlyAccessEnabled = "false";
				this.serverDate.sequenceCategoryId = "355341";
			}
			this.$store.commit("setData", this.serverDate);
		},
		initializeSearchHistoryArray() {
			let searches = JSON.parse(localStorage.getItem("searchHistory")) || [];
			this.$store.commit("setSearchHistoryArray", searches);
		},
		retriveCatGroupIdsJSON(catGroupIds) {
			const jsonStringaModificata = catGroupIds
				.slice(1, -1)
				.replace(" Glasses", "")
				.replace(/=/g, ":")
				.replace(/(\w+\s+\w+):/g, '"$1":')
				.replace(/(\s*([^,:{}\s]+)\s*):/g, '"$2":');

			return JSON.parse("{" + jsonStringaModificata + "}");
		},
	},
};
</script>
<style>
#app {
	font-family: "DM Sans", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
	overflow-x: hidden;
}
</style>
