<template>
    <div v-html="marketingHtml" class="root-espot-container"></div>
</template>

<script>
import espotContainer from './espotContainer.js';
export default {
    ...espotContainer,
};
</script>  
<style lang="scss">
@import './espotContainer.scss';
</style>