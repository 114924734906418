export default {
	name: "InputText",
	props: {
		leftIcon: {
			type: Object,
			required: false,
		},
		rightIcon: {
			type: Object,
			required: false,
		},
		leftIconFunction: {
			type: Function,
			required: false,
		},
		rightIconFunction: {
			type: Function,
			required: false,
		},
		onKeyUpProp: {
			type: Function,
		},
	},
	methods: {
		onKeyUp(event) {
			if (event?.key === "Enter" && this.$store.state.searchResponse.recordSetTotal > 0) {
				let url = "";
				if (this.$store.state.data && this.$store.state.searchResponse.searchKeyword) {
					url = "/SearchDisplay?searchTerm=" + this.$store.state.searchResponse.searchKeyword + "&beginIndex=0&pageSize=&catalogId=" + this.$store.state.data.catalogId + "&langId=-1&storeId=" + this.$store.state.data.storeId + "&viewAllClicked=true";
				}
				window.location.href = url;
			} else if (!!this.onKeyUpProp) {
				this.onKeyUpProp(this.$store.state.inputText);
			}
		},
		clickIcon(id) {
			switch (id) {
				case "left":
					break;
				case "right":
					!!this.rightIconFunction ? this.rightIconFunction() : this.defaultrightIconFunction();
					break;
			}
		},
		defaultrightIconFunction() {
			this.$store.commit("setInputText", "");
			this.onKeyUp();
		}
	},
};
