// SearchComponent.js SOLO JAVASCRIPT
import searchIcon from "@/assets/search-icon.vue";
import closeIcon from "@/assets/close-icon.vue";
import InputText from "@/components/basicComponents/inputText/InputText.vue";
import TilesGrid from "@/components/basicComponents/tilesGrids/tilesGrid/TilesGrid.vue";
import SearchHome from "@/components/searchHome/SearchHome.vue";
import SearchResult from "@/components/searchResult/SearchResult.vue";
import LoadingTilesGrid from "../basicComponents/tilesGrids/loadingTilesGrid/LoadingTilesGrid.vue";
import * as utils from "@/utils.js";

export default {
	name: "SearchComponent",
	components: {
		InputText,
		TilesGrid,
		SearchHome,
		SearchResult,
		LoadingTilesGrid,
	},
	props: {
		serverDate: Object,
	},
	props: ["serverDate"],
	data() {
		return {
			items: [],
			input: "",
			searchTimer: null,
			searchIcon: searchIcon,
			closeIcon: closeIcon,
		};
	},
	methods: {
		search(value) {
			this.cleanSearchResult();
			// Annulla il timer esistente se presente
			if (this.searchTimer) {
				clearTimeout(this.searchTimer);
			}

			this.searchTimer = setTimeout(() => {
				this.getSuggestions(value);
				this.callSearchService(value);
			}, 300);
		},
		callSearchService(value) {
			if (value.length >= 3) {
				if (this.$store.state.data) {
					utils
						.doSearchBySearchTerm(this.$store.state.data, value)
						.then((response) => {
							// Controlla se lo store, suggestions, suggestionView e entry sono definiti prima di accedere a term
							let suggestedTerm = value;
							if (this.$store.state.suggestions &&
								this.$store.state.suggestions.suggestionView &&
								this.$store.state.suggestions.suggestionView[0] &&
								this.$store.state.suggestions.suggestionView[0].entry &&
								this.$store.state.suggestions.suggestionView[0].entry[0]
							) {
								suggestedTerm = this.$store.state.suggestions.suggestionView[0].entry[0].term;
							}
							if (response.data.recordSetTotal == 0 && suggestedTerm !== value) {
								this.search(suggestedTerm.replace(/&amp;/g, "&"));
							} else {
								this.items = utils.createSearchByTermResponse(this.$store.state.data, response);
								this.saveSearchResult(utils.createSearchByTermResponse(this.$store.state.data, response), response.data.recordSetTotal, value);
							}
							this.saveSearch(this.$store.state.inputText);
						})
						.catch((error) => {
							console.error("Errore nella chiamata GET:" + error);
							this.saveSearchResult([], 0, value);
							this.saveSearch(this.$store.state.inputText);
						});
				} else {
					console.error("serverDate non definito");
				}
			}
		},
		filteredList() {
			return this.fruits.filter((fruit) => fruit.toLowerCase().includes(this.input));
		},
		saveSearch(searchValue) {
			var currentSearchArray = this.$store.state.searchHistoryArray;
			const index = currentSearchArray.indexOf(searchValue);
			if (index !== -1) {
				currentSearchArray.splice(index, 1);
			}
			currentSearchArray.unshift(searchValue);
			if (currentSearchArray.length > 3) {
				currentSearchArray.pop();
			}
			localStorage.setItem("searchHistory", JSON.stringify(currentSearchArray));
			this.$store.commit("setSearchHistoryArray", currentSearchArray);
		},

		saveSearchResult(response, recordSetTotal, searchKeyword) {
			var currentSearchResponse = {
				serviceExecuted: true,
				response: response,
				recordSetTotal: recordSetTotal,
				searchKeyword: searchKeyword,
			};
			if (typeof tealium_data2track !== "undefined" && tealium_data2track !== null) {
				tealium_data2track.push({
					id: "Event",
					Events_SearchRun: "1",
					Search_Keyword: searchKeyword, // user input text query
					Search_KeyActual: searchKeyword, // actual query used by the search tool to show the results
					Search_Type: "text", // can be "text","suggested","img" or "barcode"
					Search_View: "SERB", // describe the search engine result type of view
					Search_ResultItemsQnt: response.length.toString(), // number of returned results,0 if no results
				});
			}
			this.$store.commit("setSearchResponse", currentSearchResponse);
		},
		cleanSearchResult() {
			this.items = [];
			var currentSearchResponse = {
				serviceExecuted: false,
				response: {},
			};
			this.$store.commit("setSearchResponse", currentSearchResponse);
			this.$store.commit("setSuggestions", {});
		},
		getSuggestions(keyword) {
			if (keyword.length >= 3) {
				if (this.$store.state.data) {
					utils
						.getSuggestionsBySearchTerm(this.$store.state.data, keyword)
						.then((response) => {
							// Imposta i suggerimenti nello store
							this.$store.commit("setSuggestions", response.data);
		
							// Controlla se il termine suggerito è diverso e se il risultato della ricerca è vuoto
							let suggestedTerm = response.data?.suggestionView[0]?.entry[0]?.term;
							let searchResultEmpty = this.items.length === 0;
							if (suggestedTerm && suggestedTerm !== keyword && searchResultEmpty && this.$store.state.searchResponse.serviceExecuted == true) {
								this.search(suggestedTerm.replace(/&amp;/g, "&")); // Attiva la ricerca per il termine suggerito
							}
						})
						.catch((error) => {
							console.error("Errore nella chiamata GET:", error);
						});
				} else {
					console.error("serverDate non definito");
				}
			}
		},		
		handleClosing() {
			$("#app").closest(".modal").hide();
			$("#content_wrapper_box").css("z-index", "");
			$("html").removeClass("noScroll");
		},
	},
};
