<template>
	<div>
		<svg width="16" height="16" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M16 0.477159L15.5228 0L8 7.52284L0.477159 0L0 0.477159L7.52284 8L0 15.5228L0.477159 16L8 8.47716L15.5228 16L16 15.5228L8.47716 8L16 0.477159Z" fill="black" />
		</svg>
	</div>
</template>

<script>
export default {
	name: "close-icon",
};
</script>
