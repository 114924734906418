import NoResult from "@/components/basicComponents/noResult/NoResult.vue";
import EspotContainer from "@/components/basicComponents/espotContainer/EspotContainer.vue";

export default {
	name: "noResultComponent",
	components: {
		NoResult,
		EspotContainer
	},
	data() {},
	methods: {},
};
