import { createStore } from "vuex";

export default createStore({
	state() {
		return {
			data: {},
			inputText: "",
			searchHistoryArray: [],
			searchResponse: {
				serviceExecuted: false,
				response: {},
				recordSetTotal: 0,
				searchKeyword: "",
			},
			bestSellerResponse: {
				serviceExecuted: false,
				response: {},
			},
			noResultsProductsResponse: {
				serviceExecuted: false,
				response: {},
			},
			suggestions: {},
			marketingContent: {},
		};
	},
	mutations: {
		setData(state, newData) {
			state.data = newData;
		},
		setInputText(state, newInputText) {
			state.inputText = newInputText;
		},
		setSearchHistoryArray(state, newSearchHistoryArray) {
			state.searchHistoryArray = newSearchHistoryArray;
		},
		setSearchResponse(state, newSearchResponse) {
			state.searchResponse = newSearchResponse;
		},
		setSuggestions(state, newSuggestions) {
			state.suggestions = newSuggestions;
		},
		setBestSellerResponse(state, bestSellerResponse) {
			state.bestSellerResponse = bestSellerResponse;
		},
		setNoResultsProductsResponse(state, NoResultsProductsResponse) {
			state.noResultsProductsResponse = NoResultsProductsResponse;
		},
		setMarketingContent(state, payload) {
			state.marketingContent[payload.espotName] = payload.content;
		},
	},
	actions: {},
	getters: {},
});
